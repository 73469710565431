import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { positions, Provider as Providers } from "react-alert";
import { useDispatch } from 'react-redux';

import AlertTemplate from "react-alert-template-basic";

import Home from '../components/pages/home/Home';
import Login from '../components/auth/Login';
// import Register from '../components/auth/Register';
import Success from '../components/notification/Success';
// import NewRegister from '../components/auth/NewRegister';
import Logout from '../components/auth/Logout';
import NewPassword from '../components/reset/NewPassword';
import ForgotPassword from '../components/reset/ForgotPassword';
// import ForgotUsername from '../components/reset/ForgotUsername';
import Profile from '../components/pages/profile/Profile';
import MainWallet from '../components/pages/mainwallet/MainWallet';

import Authenticate from '../hoc/Authenticate';
import Topup from '../components/pages/topup/Topup';
// import Transfer from '../components/pages/transfer/Transter';
// import Withdraw from '../components/pages/withdraw/Withdraw';
// import SavedWallet from '../components/pages/savedwallet/SavedWallet';
// import SavedWalletList from '../components/pages/savedwallet/SavedWalletList';
// import LinkedAccount from '../components/pages/linkedaccount/LinkedAccount';
// import LinkedAccountList from '../components/pages/linkedaccount/LinkedAccountList';
import TransactionHistory from '../components/pages/transactionhistory/TransactionHistory';
import Notification from '../components/pages/notification/Notification';
import LoginMobile from '../components/auth/LoginMobile';
import DetailToken from '../components/pages/detailtoken/DetailToken';
import Translation from '../container/translation/Translation';
import TopupDwa from '../components/pages/topupdwa/TopupDwa';
import ProgressTopupDwa from '../components/pages/progress/ProgressTopupDwa';

const options = {
	timeout: 5000,
	position: positions.TOP_RIGHT
};

const Router = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(function(position) {
			dispatch({
				type : 'LATITUDE',
				newLatitude : position.coords.latitude
			});

			dispatch({
				type : 'LONGITUDE',
				newLongitude : position.coords.longitude
			});
		  });
	}, []);

    return(
        <>
			<Providers template={AlertTemplate} {...options}>
				<BrowserRouter>
					<Routes>
						<Route element={<Authenticate/>}>
							<Route path='/profile' element={<Profile/>}/>
							<Route path='/home' element={<MainWallet/>}/>
							<Route path='/topup' element={<Topup/>}/>
							<Route path='/topupdwa' element={<TopupDwa/>}/>
							{/* <Route path='/transfer' element={<Transfer/>}/> */}
							{/* <Route path='/withdraw' element={<Withdraw/>}/> */}
							{/* <Route path='/savedwallet' element={<SavedWallet/>}/> */}
							{/* <Route path='/savedwalletlist' element={<SavedWalletList/>}/> */}
							{/* <Route path='/linkedaccount' element={<LinkedAccount/>}/> */}
							{/* <Route path='/linkedaccountlist' element={<LinkedAccountList/>}/> */}
							<Route path='/transactionhistory' element={<TransactionHistory/>}/>
							<Route path='/notification' element={<Notification/>}/>
							<Route path='/detailtoken/:id' element={<DetailToken/>}/>
						</Route>
						
						<Route path='/' element={<Translation/>}/>
						<Route path='/product' element={<Home/>}/>
						<Route path='/login' element={<Login/>}/>
						<Route path='/auth' element={<LoginMobile/>}/>
						{/* <Route path='/register' element={<Register/>}/> */}
						<Route path='/success' element={<Success/>}/>
						<Route path='/new-password/:id' element={<NewPassword/>}/>
						<Route path='/forgot-password' element={<ForgotPassword/>}/>
						{/* <Route path='/forgot-username' element={<ForgotUsername/>}/> */}
						<Route path='/logout' element={<Logout/>}/>
						
						{/* <Route path='/new-register' element={<NewRegister/>}/> */}
						{/* <Route path='/new-register/:refby' element={<NewRegister/>}/> */}
						{/* <Route path='/updatetranslation' element={<Translation/>}/> */}
						<Route path='/transaction-progress/:id' element={<ProgressTopupDwa/>}/>

						<Route path='*' element={<Navigate to="/login" replace />}/>
					</Routes>
				</BrowserRouter>
			</Providers>
        </>
    );
}

export default Router;