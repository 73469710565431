import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import { MultiLanguage } from "../../lang/Language";

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('useruuid')){
            let path = '/home';
            navigate(path); 
        }
        
        if(localStorage.getItem('Translation') === null){
            navigate('/updatetranslation');
        }
    }, []);
    return(
        <>
            <div className="application">
                <Navbar/>
                <div className="mt-10rem">
                    <div className="launchpad">
                        <img src={process.env.PUBLIC_URL+'/images/components/xdw-background.png'} alt="XDW" width="100%" height="550" className="objectfit"/>
                    </div>
                    <div className="launchpad">
                        <div className="card box shadow">
                            <div className="card-body">
                                <img src={process.env.PUBLIC_URL+'/images/components/Image-1.png'} alt="image.png" className="image-center mt-4"/>
                                <h3 className="text-center pt-4">ANOA Token</h3>
                                <center>
                                    {/* <p className="text-center w-163">Gaming Future is in Your Hand</p> */}
                                    <p className="text-center w-163">{MultiLanguage('DESCRIPTION_ONE')}</p>
                                </center>
                            </div>
                        </div>

                        <div className="card box shadow">
                            <div className="card-body">
                                <img src={process.env.PUBLIC_URL+'/images/components/Image-2.png'} alt="image.png" className="image-center mt-4"/>
                                <h3 className="text-center pt-4">CST Token</h3>
                                <center>
                                    {/* <p className="text-center w-163">Best Crypto Solution for Your Community</p> */}
                                    <p className="text-center w-163">{MultiLanguage('DESCRIPTION_TWO')}</p>
                                </center>
                            </div>
                        </div>

                        <div className="card box shadow">
                            <div className="card-body">
                                <img src={process.env.PUBLIC_URL+'/images/components/Image-3.png'} alt="image.png" className="image-center mt-4"/>
                                <h3 className="text-center pt-4">XDW Token</h3>
                                <center>
                                    {/* <p className="text-center w-163">The best launchpad for professional teams</p> */}
                                    <p className="text-center w-163">{MultiLanguage('DESCRIPTION_THREE')}</p>
                                </center>
                            </div>
                        </div>

                        {/* <div className="card box shadow">
                            <div className="card-body">
                                <img src={process.env.PUBLIC_URL+'/images/components/Image.png'} alt="image.png" className="image-center mt-4"/>
                                <h3 className="text-center pt-4">Launchpad 4</h3>
                                <center>
                                    <p className="text-center w-163">The best launchpad for professional teams</p>
                                </center>
                            </div>
                        </div>

                        <div className="card box shadow">
                            <div className="card-body">
                                <img src={process.env.PUBLIC_URL+'/images/components/Image.png'} alt="image.png" className="image-center mt-4"/>
                                <h3 className="text-center pt-4">Launchpad 5</h3>
                                <center>
                                    <p className="text-center w-163">The best launchpad for professional teams</p>
                                </center>
                            </div>
                        </div>

                        <div className="card box shadow">
                            <div className="card-body">
                                <img src={process.env.PUBLIC_URL+'/images/components/Image.png'} alt="image.png" className="image-center mt-4"/>
                                <h3 className="text-center pt-4">Launchpad 6</h3>
                                <center>
                                    <p className="text-center w-163">The best launchpad for professional teams</p>
                                </center>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;