import axios from "axios";
import { useEffect } from "react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const Translation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        axios({
            method : 'POST',
            url : process.env.REACT_APP_MEGALOMAN+'/api/project/export_translation',
            data : {
                tableprojectindex : process.env.REACT_APP_PROJECTTRANSLATION
            }
        }).then(res => {
            if(res.data.status.status === 1){
                let StringJson = JSON.stringify(res.data.result.tabletranslation);
                let Encrypt = CryptoJS.AES.encrypt(StringJson, process.env.REACT_APP_SECRET).toString();
                let encode  = btoa(Encrypt);

                localStorage.setItem('Translation', encode);

                navigate('/product');
            }
        })
    }, []);

    return(
        <>Being prepared please wait...</>
    );
}

export default Translation;